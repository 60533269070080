// extracted by mini-css-extract-plugin
export var contactPage = "farNJ";
export var content = "nmh4b";
export var contentPage = "sz3Um";
export var foundationPage = "frYNR";
export var image = "_1JdJP";
export var image_fullwidth = "p8yH9";
export var image_left = "KiKcn";
export var image_right = "gg8DO";
export var jobPage = "dzxss";
export var linkList = "_32Qn1";
export var map = "VHFzm";
export var newsPage = "yw7UJ";